















import {Component, Vue} from 'vue-property-decorator';
@Component({
  name: 'page-access-denied'
})
export default class PageAccessDenied extends Vue {
  public showBtn: boolean = true
  get title() {
    return 'Access denied'
  }
}
